<template>
	<section class="section4 text-center">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="owl-slider">
						<OwlCarousel :items="carouselItems"  @active-item-changed="handleActiveItemChange"/>
					</div>	
					<button class="btn blueBtn2" @click="$router.push('/instructions/step-3')" v-if="activeItemIndex === 1">{{ $t('instructions.step2.next') }}</button>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { mapGetters } from 'vuex';
import OwlCarousel from '~/components/OwlCarousel.vue'; // Import the OwlCarousel component

export default {
  components: {
    OwlCarousel,
  },
  data() {
    return {
      carouselItems: [],
      activeItemIndex: 1
    };
  },
  computed: {
    ...mapGetters({
      testFor: 'inhaler/getTestingFor',
    }),
  },
  created() {
    let firstSliderText = (this.testFor === 'me') ? '<h2 class="darkblueTxt">'+this.$t('instructions.step2.slider_1_heading')+'</h2><img src="/images/instruction.png" alt="Instruction" class="mx-auto d-block img-fluid my-3"/><p class="lightblueTxt m-0">'+this.$t('instructions.step2.slider_1_text_me')+'' : '<h2 class="darkblueTxt">'+this.$t('instructions.step2.slider_1_heading')+'</h2><img src="/images/instruction.png" alt="Instruction" class="mx-auto d-block img-fluid my-3"/><p class="lightblueTxt m-0">'+this.$t('instructions.step2.slider_1_text_other')+'</p>';
    let secondSliderText = '<h2 class="darkblueTxt">'+this.$t('instructions.step2.slider_2_heading')+'</h2><img src="/images/instruction.png" alt="Instruction" class="mx-auto d-block img-fluid my-3"/><p class="lightblueTxt m-0"><strong>'+this.$t('instructions.step2.slider_2_text')+'';
    this.carouselItems = [
      { id: 1, htmlContent: firstSliderText },
      { id: 2, htmlContent: secondSliderText },
      // Add more carousel items with HTML content as needed
    ];
  },
  methods: {
    handleActiveItemChange(index) {
      // Update the active item index in the parent component
      //this.activeItemIndex = index;
      index = 1;
      this.activeItemIndex = index;
    },
  },
};
</script>