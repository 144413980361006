<!-- OwlCarousel.vue -->
<template>
  <div class="owl-carousel owl-theme">
    <div class="item" v-for="item in items" :key="item.id">
      <!-- Render HTML content using v-html -->
      <div v-html="item.htmlContent"></div>
    </div>
  </div>
</template>

<script>
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import $ from 'jquery';

export default {
  props: {
    items: Array, // An array of carousel items (each with an htmlContent property)
  },
  mounted() {
    // Initialize Owl Carousel when the component is mounted
    $(this.$el).owlCarousel({
      items: 3, // Number of items to display
      loop: false, // Infinite loop
      margin: 10, // Spacing between items
      autoplay: true, // Auto-play the carousel
      autoplayTimeout: 3000, // Auto-play timeout in milliseconds
      responsive: {
        0: {
          items: 1, // Number of items to display on small screens
        },
        600: {
          items: 3, // Number of items to display on larger screens
        },
      },
      onTranslated: this.handleCarouselChange,
    });
  },
  beforeDestroy() {
    // Destroy Owl Carousel when the component is unmounted to prevent memory leaks
    $(this.$el).owlCarousel('destroy');
  },
  data() {
    return {
      activeItemIndex: 0, // Initialize with the default active item index
    };
  },
  methods: {
    handleCarouselChange(event) {
      // Update the active item index
      this.activeItemIndex = event.page.index;
      // Emit a custom event to send the active item index to the parent component
      this.$emit('active-item-changed', this.activeItemIndex);
    },
  }
};
</script>